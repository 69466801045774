type StatusCodes = "630" | "632" | "901" | "410" | "413" | "616" | "620" | "624" | "625" | "631" | "638" | "640" | "641" | "642" | "643" | "644" | "645" | "646" | "647" | "648" | "649" | "650" | "651" | "652" | "653" | "654" | "655" | "656" | "657" | "658" | "659" | "660" | "661" | "663" | "664" | "665" | "666" | "667" | "668" | "672" | "680" | "681" | "682" | "686" | "688" | "689" | "690" | "692" | "693" | "694" | "700" | "701" | "702" | "703" | "704" | "705" | "706" | "707" | "708" | "717" | "718" | "719" | "720" | "721" | "722" | "724" | "725" | "730" | "731" | "732" | "733" | "734" | "739" | "740" | "744" | "745" | "746" | "748" | "752" | "753" | "756" | "757" | "760" | "762" | "763" | "764" | "765" | "766" | "771" | "772" | "773" | "774" | "775" | "777" | "778" | "779" | "780" | "790" | "794" | "800" | "801" | "802" | "803" | "804" | "806" | "807" | "808" | "809" | "810" | "811" | "812" | "813" | "814" | "815" | "816" | "817" | "818" | "819" | "820" | "821" | "822" | "823" | "824" | "825" | "973" | "969" | "622" | "970" | "600" | "601" | "602" | "603" | "604" | "605" | "606" | "607" | "608" | "609" | "614" | "709" | "710" | "711" | "712" | "713" | "714" | "715" | "900" | "622" | "612" | "400" | "401" | "402" | "403" | "404" | "405" | "406" | "411" | "412" | "414" | "415" | "416" | "417" | "618" | "626";
type Status = "LIVE" | "DEAD" | "INDIFFERENT";
type Stage = "REGISTERED" | "NEW" | "ACCEPTED" | "PRE-REGISTRATION" | "PUBLISHED";
type UsptoStatus = "LIVE" | "DEAD" | "OTHER";

interface KeyValueMap<T> {
    [key: string]: T;
}

interface StatusCodeRecord {
    code: StatusCodes,
    status: Status,
    status2: string,
    stage?: Stage, 
    usptoStatus: UsptoStatus,
    soonDead: boolean,
    recentlyDead: boolean,
    definition: string,
    detail: string,
    soonLive?: boolean,
}

const statusCodes: StatusCodeRecord[] = [
    // LIVE 
    {code: '410', status: 'LIVE', status2: 'PENDING', stage: null, usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'IR RESTRICTED; US APP.  ABANDONED, PENDING TRANSFORMATION', detail: ''},
    {code: '413', status: 'LIVE', status2: 'PENDING', stage: null, usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'IR RESTRICTED; US REG. CANCELLED PEND TRANSFORMATION', detail: ''},
    {code: '616', status: 'LIVE', status2: 'PENDING', stage: null, usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REVIVED - AWAITING FURTHER ACTION', detail: ''},
    {code: '620', status: 'LIVE', status2: 'PENDING', stage: null, usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'BACKFILE APPLICATION ADDED TO DATA BASE - STATUS NOT RECORDED', detail: 'Indicates the application existed when the TRAM database was created and the status is unknown at this time.'},
    {code: '624', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REGISTERED - BACKFILE', detail: 'Indicates the registration existed when the TRAM database was created (before the digital system) and remains registered at this time.'},
    {code: '625', status: 'LIVE', status2: 'REGISTERED', stage: null, usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REGISTRATION ADDED TO THE DATA BASE-STATUS UNCLEAR ', detail: 'Indicates the registration status is unknown at this time.'},
    {code: '630', status: 'LIVE', status2: 'PENDING (NEW)', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'NEW APPLICATION - RECORD INITIALIZED NOT ASSIGNED TO EXAMINER', detail: ''},
    {code: '631', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'NEW APPLICATION - DIVIDED - INITIAL PROCESSING', detail: 'Indicate the trademark is a product of dividing a previous one'},
    {code: '638', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'NEW APPLICATION - ASSIGNED TO EXAMINER', detail: ''},
    {code: '640', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'NON-FINAL ACTION COUNTED - NOT MAILED', detail: ''},
    {code: '641', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'NON-FINAL ACTION - MAILED', detail: ''},
    {code: '642', status: 'LIVE', status2: 'PENDING', stage: null, usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'INVENTORIED AS REJECTED', detail: ''},
    {code: '643', status: 'LIVE', status2: 'PENDING', stage: null, usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'PREVIOUS ACTION/ALLOWANCE COUNT WITHDRAWN ', detail: 'Indicate previously Allowance issued for an intent to use is withdrawn'},
    {code: '644', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'FINAL REFUSAL COUNTED - NOT MAILED', detail: 'Indicates a final Office action refusing registration has been issued'},
    {code: '645', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'FINAL REFUSAL - MAILED ', detail: 'Indicates a final Office action refusing registration has been sent (issued)'},
    {code: '646', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'EXAMINERS AMENDMENT COUNTED - NOT MAILED', detail: 'Indicates trademark requires some minor change to proceed'},
    {code: '647', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'EXAMINERS AMENDMENT - MAILED', detail: 'Indicates trademark requires some minor change to proceed.'},
    {code: '648', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'ACTION CONTINUING FINAL COUNTED - NOT MAILED', detail: 'Final refusal is counted (not mailed) after a request to reconsider initial refusal was received.'},
    {code: '649', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'ACTION CONTINUING FINAL - MAILED', detail: 'Final refusal is mailed after a request to reconsider initial refusal was received.'},
    {code: '650', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SUSPENSION INQUIRY COUNTED - NOT MAILED', detail: 'Trademark suspended due to a likelihood of confusion with other existing trademarks.'},
    {code: '651', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SUSPENSION INQUIRY - MAILED', detail: 'Trademark suspended due to a likelihood of confusion with other existing trademarks.'},
    {code: '652', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SUSPENSION LETTER COUNTED - NOT MAILED', detail: 'Trademark suspended due to a likelihood of confusion with other existing trademarks.'},
    {code: '653', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SUSPENSION LETTER - MAILED', detail: 'Trademark suspended due to a likelihood of confusion with other existing trademarks.'},
    {code: '654', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REPORT COMPLETED SUSPENSION CHECK - CASE STILL SUSPENDED', detail: 'Trademark suspended due to a likelihood of confusion with other existing trademarks.'},
    {code: '655', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'EXAMINER\'S AMENDMENT/PRIORITY ACTION COUNTED NOT MAILED', detail: ''},
    {code: '656', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'EXAMINER\'S AMENDMENT/PRIORITY ACTION MAILED', detail: ''},
    {code: '657', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'PRIORITY ACTION COUNTED NOT MAILED', detail: ''},
    {code: '658', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'PRIORITY ACTION MAILED', detail: ''},
    {code: '659', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'SUBSEQUENT FINAL REFUSAL COUNTED NOT MAILED', detail: ''},
    {code: '660', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'SUBSEQUENT FINAL MAILED', detail: ''},
    {code: '661', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'RESPONSE AFTER NON-FINAL ACTION - ENTERED', detail: ''},
    {code: '663', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'RESPONSE AFTER FINAL REJECTION - ENTERED', detail: 'Appeal to TTAB was filed'},
    {code: '664', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'INVENTORIED AS AMENDED', detail: ''},
    {code: '665', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'NOTICE OF UNRESPONSIVE AMENDMENT - COUNTED', detail: ''},
    {code: '666', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'NOTICE OF UNRESPONSIVE AMENDMENT - MAILED', detail: ''},
    {code: '667', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REFUSAL WITHDRAWAL LETTER - COUNTED', detail: ''},
    {code: '668', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REFUSAL WITHDRAWAL LETTER - MAILED', detail: ''},
    {code: '672', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REINSTATED - AWAITING FURTHER ACTION', detail: ''},
    {code: '680', status: 'LIVE', status2: 'PRE-OPPOSITION', stage: "ACCEPTED", usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'APPROVED FOR PUBLICATION', detail: 'Trademark will be published for opposition in the near future once a publication committee approves it.'},
    {code: '681', status: 'LIVE', status2: 'PRE-OPPOSITION', stage: "ACCEPTED", usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'PUBLICATION/ISSUE REVIEW COMPLETE', detail: 'Approved by publication committee and will be published for opposition in the near future.'},
    {code: '682', status: 'LIVE', status2: 'PENDING', stage: "ACCEPTED", usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'ON HOLD - ADDITIONAL PUB REVIEW REQUIRED', detail: ''},
    {code: '686', status: 'LIVE', status2: 'OPPOSITION', stage: 'PUBLISHED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'PUBLISHED FOR OPPOSITION', detail: 'Trademark published for opposition and has 30 days from the day of publication before moving to registration or allowance'},
    {code: '688', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'NOTICE OF ALLOWANCE - ISSUED', detail: ''},
    {code: '689', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'NOTICE OF ALLOWANCE - WITHDRAWN', detail: 'Trademark has passed the opposition stage and received an allowance. Then an obstacle occurred that caused the allowance to be withdrawn'},
    {code: '690', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'NOTICE OF ALLOWANCE - CANCELLED', detail: 'Trademark has passed the opposition stage and received an allowance. Then an obstacle occurred that caused the allowance to be cancelled'},
    {code: '692', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'WITHDRAWN BEFORE PUBLICATION', detail: 'The trademark application was withdrawn (usually voluntarily) before publication.'},
    {code: '693', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'WITHDRAWN FROM ISSUE-JURISDICTION RESTORED', detail: 'Jurisdiction returned to USPTO examining attorney.'},
    {code: '694', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'WITHDRAWN BEFORE ISSUE', detail: 'The trademark application was withdrawn (usually voluntarily) before registration.'},
    
    {code: '700', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REGISTERED', detail: 'Trademark has been registered successfully'},
    {code: '701', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SECTION 8-ACCEPTED', detail: 'U.S. Trademark Application Renewed Under Section 8'},
    {code: '702', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SECTION 8 & 15-ACCEPTED AND ACKNOWLEDGED', detail: ''},
    {code: '703', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SECTION 15-ACKNOWLEDGED', detail: ''},
    {code: '704', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'PARTIAL SECTION 8 ACCEPTED', detail: ''},
    {code: '705', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'PARTIAL SECTION 8 & 15 ACCEPTED AND ACKNOWLEDGED', detail: ''},
    {code: '706', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SECTION 71 ACCEPTED', detail: ''},
    {code: '707', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'PARTIAL SECTION 71 ACCEPTED', detail: ''},
    {code: '708', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'PARTIAL SECTION 71 & 15 ACCEPTED AND ACKNOWLEDGED', detail: ''},
    {code: '717', status: 'LIVE', status2: 'PENDING', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REGISTERED - AWAITING DIVISIONAL FEE', detail: 'Trademark has been divided and is awaiting fees.'},
    {code: '718', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REQUEST FOR FIRST EXTENSION - FILED', detail: ''},
    {code: '719', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REQUEST FOR SECOND EXTENSION - FILED', detail: ''},
    {code: '720', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REQUEST FOR THIRD EXTENSION - FILED', detail: ''},
    {code: '721', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REQUEST FOR FOURTH EXTENSION - FILED', detail: ''},
    {code: '722', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REQUEST FOR FIFTH EXTENSION - FILED', detail: ''},
    {code: '724', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'EXTENSION REQUEST REFUSAL - COUNTED', detail: ''},
    {code: '725', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'EXTENSION REQUEST REFUSAL - MAILED', detail: ''},
    {code: '730', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'FIRST EXTENSION - GRANTED', detail: 'Request to extend trademark allowance is granted.'},
    {code: '731', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SECOND EXTENSION - GRANTED', detail: 'Second request to extend trademark allowance is granted.'},
    {code: '732', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'THIRD EXTENSION - GRANTED', detail: 'Third request to extend trademark allowance is granted.'},
    {code: '733', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'FOURTH EXTENSION - GRANTED', detail: 'Fourth request to extend trademark allowance is granted.'},
    {code: '734', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'FIFTH EXTENSION - GRANTED', detail: 'Fifth request to extend trademark allowance is granted.'},
    {code: '739', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'Section 71 & 15 – ACCEPTED AND ACKNOWLEDGED', detail: ''},
    {code: '740', status: 'LIVE', status2: 'PENDING', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'POST REGISTRATION PAPER FILED - ASSIGNED TO PARA-LEGAL', detail: 'Typically means a new document has been filed (by applicant) related to a registered trademark.'},
    {code: '744', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'STATEMENT OF USE - FILED', detail: 'Trademark was allowed under the basis of "intent to use" and now a "statement of use" has been filed.'},
    {code: '745', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'STATEMENT OF USE - INFORMAL-LETTER MAILED', detail: 'Applicant filed a statement of use but a minor issue has been found and will a letter is mailed to applicant.'},
    {code: '746', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'STATEMENT OF USE - INFORMAL-RESPONSE ENTERED', detail: 'Applicant filed a statement of use but a minor issue has been found and will a letter is mailed to applicant.'},
    {code: '748', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'STATEMENT OF USE - TO EXAMINER', detail: 'After filing a statement of use, a defect might have been found and the application is sent back to the examiner.'},
    {code: '752', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - EXAMINER STATEMENT COUNTED - NOT MAILED', detail: 'Examining Attorney has identified some issues with the statement of use.'},
    {code: '753', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - EXAMINER STATEMENT - MAILED', detail: 'Examining Attorney has identified some issues with the statement of use. A letter explaining those issues has been mailed.'},
    {code: '756', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'EXAMINER STATEMENT COUNTED - NOT MAILED', detail: 'Examining Attorney has identified some issues with the application the will preclude registration.'},
    {code: '757', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'EXAMINER STATEMENT - MAILED', detail: 'Examining Attorney has identified some issues with the application the will preclude registration. A letter explaining those issues has been mailed.'},
    {code: '760', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'EX PARTE APPEAL PENDING', detail: 'Trademark application was refused and the applicant has appealed to the appeal board.'},
    {code: '762', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'EX PARTE APPEAL TERMINATED', detail: 'Trademark application was refused and the applicant has appealed to the appeal board and a decision was made.'},
    {code: '763', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'EX PARTE APPEAL-REFUSAL AFFIRMED', detail: 'Trademark application was refused and the applicant has appealed to the appeal board and a decision was made to refuse the registration of this trademark.'},
    {code: '764', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'EX PARTE APPEAL DISMISSED AS MOOT', detail: 'Trademark application was refused and the applicant has appealed to the appeal board and a decision was made dismiss the case as moot.'},
    {code: '765', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'CONCURRENT USE PROCEEDING TERMINATED-GRANTED', detail: 'Trademark was accepted for registration as a concurrent mark to a similar existing trademark (which means it can be used as long as it meets certain geographical restrictions)'},
    {code: '766', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'CONCURRENT USE PROCEEDING TERMINATED-DENIED', detail: 'Trademark was denied for use as a concurrent mark (which means it can be used along with a similar trademark as long as it meets certain geographical restrictions)'},
    {code: '771', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'CONCURRENT USE PROCEEDING PENDING', detail: 'Applicant requested that the trademark be used concurrent to similar existing trademark.'},
    {code: '772', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'INTERFERENCE PROCEEDING PENDING', detail: ''},
    {code: '773', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'EXTENSION OF TIME TO OPPOSE PROCESS - TERMINATED', detail: 'A request to extend the opposition period on this trademark has been terminated.'},
    {code: '774', status: 'LIVE', status2: 'PENDING', stage: 'PUBLISHED', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'OPPOSITION PENDING', detail: 'A party has filed an opposition against this trademark.'},
    {code: '775', status: 'LIVE', status2: 'PENDING', stage: 'PUBLISHED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'OPPOSITION DISMISSED', detail: 'The opposition filed against this trademark was dismissed.'},
    {code: '777', status: 'LIVE', status2: 'PENDING', stage: 'PUBLISHED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'OPPOSITION TERMINATED - SEE TTAB RECORDS', detail: 'The Trademark Trial and Appeal Board ("TTAB") proceeding has been terminated.'},
    {code: '778', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'CANCELLATION DISMISSED', detail: 'The Trademark Trial and Appeal Board ("TTAB") proceeding has been dismissed.'},
    {code: '779', status: 'LIVE', status2: 'PENDING', stage: 'PUBLISHED', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'OPPOSITION SUSTAINED', detail: 'A party has opposed this trademark. The opposition has been decided in favor of the opposer.'},
    {code: '780', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'CANCELLATION TERMINATED - SEE TTAB RECORDS', detail: 'A party has petitioned to cancel this trademark. However, the Trademark Trial and Appeal Board ("TTAB") proceeding has been dismissed.'},
    {code: '790', status: 'LIVE', status2: 'REGISTERED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'CANCELLATION PENDING', detail: 'A party has petitioned to cancel this trademark and succeeded.'},
    {code: '794', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'JURISDICTION RESTORED TO EXAMINING ATTORNEY', detail: ''},
    {code: '800', status: 'LIVE', status2: 'RENEWED', stage: 'REGISTERED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REGISTERED AND RENEWED', detail: ''},
    {code: '801', status: 'LIVE', status2: 'PENDING', stage: 'PUBLISHED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'OPPOSITION PAPERS FILED', detail: 'An opposition petition has been filed against this trademark.'},
    {code: '802', status: 'LIVE', status2: 'PENDING', stage: 'PUBLISHED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'REQUEST FOR EXTENSION OF TIME TO FILE OPPOSITION', detail: ''},
    {code: '803', status: 'LIVE', status2: 'PENDING', stage: 'PUBLISHED', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'AMENDMENT AFTER PUBLICATION', detail: ''},
    {code: '804', status: 'LIVE', status2: 'PENDING', stage: 'NEW', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'APPEAL RECEIVED AT TTAB', detail: 'An appeal to a final action has been received'},
    {code: '806', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - NON-FINAL ACTION COUNTED - NOT MAILED', detail: 'Statement of use has been filed but some issues have been found with it.'},
    {code: '807', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - NON-FINAL ACTION - MAILED', detail: 'A non-final Office Action has been sent (issued) to the applicant after review of the Statement of Use. This is a letter from the examining attorney requiring additional information and/or making an initial refusal.'},
    {code: '808', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'SU - FINAL REFUSAL COUNTED - NOT MAILED', detail: 'Statement of use has been filed but some issues have been found with it and the applicant response was not sufficient to proceed successfully. A final refusal is counted and will be mailed soon.'},
    {code: '809', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'SU - FINAL REFUSAL - MAILED', detail: 'Statement of use has been filed but some issues have been found with it and the applicant response was not sufficient to proceed successfully. A final refusal has been mailed.'},
    {code: '810', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - EXAMINER\'S AMENDMENT COUNTED - NOT MAILED', detail: ''},
    {code: '811', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - EXAMINER\'S AMENDMENT - MAILED', detail: ''},
    {code: '812', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'SU - ACTION CONTINUING FINAL COUNTED - NOT MAILED', detail: ''},
    {code: '813', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'SU - ACTION CONTINUING FINAL - MAILED', detail: ''},
    {code: '814', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - RESPONSE AFTER NON-FINAL ACTION - ENTERED', detail: ''},
    {code: '815', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - RESPONSE AFTER FINAL REJECTION - ENTERED', detail: ''},
    {code: '816', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - NOTICE OF UNRESPONSIVE AMENDMENT - COUNTED', detail: 'Examining Attorney has identified issues with the Statement of Use, and sent an "Office Action" to the applicant to explain what those issues are. The applicant responded to amend the Statement of Use, but the response amendment was not responsive to the issues identified by the Examining Attorney'},
    {code: '817', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - NOTICE OF UNRESPONSIVE AMENDMENT - MAILED', detail: 'Examining Attorney has identified issues with the Statement of Use, and sent an "Office Action" to the applicant to explain what those issues are. The applicant responded to amend the Statement of Use, but the response amendment was not responsive to the issues identified by the Examining Attorney'},
    {code: '818', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - STATEMENT OF USE ACCEPTED - APPROVED FOR REGISTRATION', detail: ''},
    {code: '819', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - REGISTRATION REVIEW COMPLETE', detail: ''},
    {code: '820', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU-EXAMINER\'S AMENDMENT/PRIORITY ACTION COUNTED NOT MAILED', detail: ''},
    {code: '821', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - EXAMINER\'S AMENDMENT/PRIORITY ACTION MAILED', detail: ''},
    {code: '822', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - PRIORITY ACTION COUNTED NOT MAILED', detail: ''},
    {code: '823', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'SU - PRIORITY ACTION MAILED', detail: ''},
    {code: '824', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'SU - SUBSEQUENT FINAL REFUSAL WRITTEN', detail: ''},
    {code: '825', status: 'LIVE', status2: 'PENDING', stage: 'PRE-REGISTRATION', usptoStatus: 'LIVE', soonDead: true, recentlyDead: false, definition: 'SU - SUBSEQUENT FINAL MAILED', detail: ''},
    {code: '973', status: 'LIVE', status2: 'PENDING', stage: null, usptoStatus: 'LIVE', soonDead: false, recentlyDead: false, definition: 'PENDING PETITION/COURT DECISION', detail: ''},
    
    // OTHER
    {code: '969', status: 'LIVE', status2: 'LIVE/Pending - OTHER', stage: null, usptoStatus: 'OTHER', soonDead: false, recentlyDead: false, definition: '', detail: ''},
    
    // INDIFFERENT
    {code: '622', status: 'INDIFFERENT', status2: '', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: '', detail: ''},
    {code: '970', status: 'INDIFFERENT', status2: '', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: '', detail: ''},
    {code: '715', status: 'INDIFFERENT', status2: 'CANCELLED', stage: null, usptoStatus: 'OTHER', soonDead: false, recentlyDead: false, definition: '', detail: ''}, 

    // DEAD CODES
    {code: '400', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'IR CANCELLED; APPLICATION PENDING TRANSFORMATION', detail: '	U.S. application abandoned because International Registration cancelled in whole or in part. Transformation into a national application is permissible'}, 
    {code: '401', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'IR CANCELLED - NO TRANSFORMATION FILED', detail: 'U.S. application abandoned because International Registration cancelled in whole or in part. No transformation filed. No petition to revive is allowed.'}, 
    {code: '402', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'IR CANCELLED--CASE ABANDONED/CANCELLED', detail: 'U.S. application abandoned because International Registration restricted, renounced, or expired, in whole or in part. No transformation or petition to revive is allowed.'},
    {code: '403', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'IR CANCELLED; REGISTRATION PENDING TRANSFORMATION', detail: 'U.S. registration cancelled because International Registration cancelled in whole or in part. Transformation into a national application is permissible.'}, 
    {code: '404', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'IR CANCELLED; US REGISTRATION CANCELLED', detail: 'U.S. registration cancelled because International Registration cancelled in whole or in part.'}, 
    {code: '405', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'IR CANCELED; APPLICATION TRANSFRMED', detail: 'U.S. application abandoned because International Registration cancelled in whole or in part. Request for extension of protection transformed into national application.'}, 
    {code: '406', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'IR CANCELLED; REGISTRATION TRANSFORMED', detail: ''}, 
    {code: '411', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'IR RESTRICTED; US APP. ABANDONED; NO TRANSFORMATION FILED', detail: 'U.S. application abandoned because International Registration restricted or cancelled, in part. No transformation filed. No petition to revive allowed.'}, 
    {code: '412', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: '', detail: ''}, 
    {code: '414', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: '', detail: ''}, 
    {code: '415', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: '', detail: ''}, 
    {code: '416', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: '', detail: ''}, 
    {code: '417', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: '', detail: ''}, 
    {code: '600', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED - INCOMPLETE RESPONSE', detail: 'Abandoned due to incomplete response. The response did not satisfy all issues in the Office action.'}, 
    {code: '601', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED - EXPRESS', detail: 'The owner of the trademark application withdrew (e.g. abandoned) the application and the application is no longer active.'}, 
    {code: '602', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED-FAILURE TO RESPOND OR LATE RESPONSE', detail: 'This trademark application was refused, dismissed, or invalidated by the Office and this application is no longer active.'}, 
    {code: '603', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED - AFTER EX PARTE APPEAL', detail: 'This trademark application was refused, dismissed, or invalidated by the Office and this application is no longer active.'}, 
    {code: '604', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED - AFTER INTER-PARTES DECISION', detail: 'This trademark application was refused, dismissed, or invalidated by the Office and this application is no longer active.'}, 
    {code: '605', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED – EXPRESS AFTER PUB', detail: 'The owner of the trademark application withdrew (e.g. abandoned) the application and the application is no longer active.'}, 
    {code: '606', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED - NO STATEMENT OF USE FILED', detail: 'This trademark application was refused, dismissed, or invalidated by the Office and this application is no longer active.'}, 
    {code: '607', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED - DEFECTIVE STATEMENT OF USE', detail: 'This trademark application was refused, dismissed, or invalidated by the Office and this application is no longer active.'}, 
    {code: '608', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED - AFTER PETITION DECISION', detail: 'This trademark application was refused, dismissed, or invalidated by the Office and this application is no longer active.'}, 
    {code: '609', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED - DEFECTIVE DIVIDED APPLICATION', detail: '	Abandoned: Divided application was defective.'}, 
    {code: '612', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, soonLive: true, recentlyDead: false, definition: 'PETITION TO REVIVE-RECEIVED', detail: 'A petition to revive has been filed.'},
    {code: '614', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED PETITION TO REVIVE-DENIED', detail: 'A petition to revive has been denied; application remains abandoned.'}, 
    {code: '618', status: 'DEAD', status2: 'ABANDONED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'ABANDONED FILE - BACKFILE', detail: 'Abandoned: Reason unknown.'},
    {code: '626', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'REGISTERED - BACKFILE CANCELLED OR EXPIRED', detail: 'Registration - backfile is cancelled or expired.'},
    {code: '632', status: 'DEAD', status2: '', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'INFORMAL APPLICATION', detail: 'Application was incomplete and is considered informal.'},
    {code: '709', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'CANCELLED – SECTION 71', detail: ''},
    {code: '710', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'CANCELLED - SECTION 8', detail: ''},
    {code: '711', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'CANCELLED - SECTION 7', detail: ''}, 
    {code: '712', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'CANCELLED BY COURT ORDER (SECTION 37)', detail: ''}, 
    {code: '713', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'CANCELLED - SECTION 18', detail: ''},
    {code: '714', status: 'DEAD', status2: 'CANCELLED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'CANCELLED - SECTION 24', detail: ''}, 
    {code: '900', status: 'DEAD', status2: 'EXPIRED', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'EXPIRED', detail: 'This registration was not renewed and therefore has expired.'},
    {code: '901', status: 'DEAD', status2: '', stage: null, usptoStatus: 'DEAD', soonDead: false, recentlyDead: false, definition: 'DEAD INVENTORIED AS REJECTED', detail: ''},
]

function getDeadCodes(): string[] {
    let deadCodes = statusCodes.filter(code => /dead/ig.test(code.status));
    return deadCodes.map(code => code.code);
}
function getSoonDeadCodes(): string[] {
    let soonDead = statusCodes.filter(code => code.soonDead);
    return soonDead.map(code => code.code);
}
function getOtherCodes(): string[] {
    let otherCodes = statusCodes.filter(code => /other/ig.test(code.usptoStatus));
    return otherCodes.map(code => code.code);
}
function getIndifferentCodes(): string[] {
    let indifferentCodes = statusCodes.filter(code => /INDIFFERENT/ig.test(code.usptoStatus));
    return indifferentCodes.map(code => code.code);
}
function getLiveCodes(): string[] {
    let liveCodes = statusCodes.filter(code => /live/ig.test(code.status));
    return liveCodes.map(code => code.code);
}

function getCodesStatusMap(): KeyValueMap<string> {
    let map: KeyValueMap<string> = {};

    statusCodes.forEach(codeRecord => {
        let value = codeRecord.status;
        if (codeRecord.status2 !== '') value += ' - ' + codeRecord.status2;

        map[codeRecord.code] = value
    });

    return map;
}

function getStatusCodes(): KeyValueMap<StatusCodeRecord> {
    let map: KeyValueMap<StatusCodeRecord> = {};

    statusCodes.forEach(record => {
        map[record.code] = record;
    })

    return map;
}

export const deadCodes = getDeadCodes();
export const soonDead = getSoonDeadCodes();
export const otherCodes = getOtherCodes();
export const indifferentCodes = getIndifferentCodes();
export const liveCodes = getLiveCodes();
export const codesStatusMap = getCodesStatusMap();
export const codes = getStatusCodes();
export default statusCodes;